<div class="d-flex flex-column py-1 card-reader">
  <!-- Title for selected item -->
  <div class="d-flex flex-row">
    <div class="ml-3 mt-2 mb-0">
      <label [attr.aria-label]="'Selected Item: ' + getSelectedText()" tabindex="0" #titleLabel>
        <h6 class="m-0">{{title}}: {{ getSelectedText() }}</h6>
      </label>
    </div>
    <div *ngIf="isTreeViewCollapsed" class="ml-auto pt-2 mr-1">
      <button type="button" class="btn p-0 mb-1" data-toggle="collapse" data-target="#TreeViewDetails" aria-label="Expand" title="Open Dropdowns" (click)="toggleTreeView()">
        <span class="fa fa-angle-double-down"></span>
      </button>
    </div>
  </div>

  <!-- Collapsible Dropdown Selection -->
  <div class="collapse show mx-2 mb-1" id="TreeViewDetails" #treeViewDivElement>
    <div class="d-flex flex-row flex-wrap">
      <!-- Level 0 Dropdown -->
      <div class="mb-2 d-flex flex-column">
        <label [attr.aria-label]="'Level ' + levelNumber + ' dropdown'" *ngIf="!loadingDropdown">Source {{levelNumber}}</label>
        <select *ngIf="!loadingDropdown" class="custom-select-sm" [(ngModel)]="selectedValues[0]" (change)="onDropdownChange(0, $event.target.value)">
          <option *ngFor="let item of getDropdownOptions(0)" [value]="item.id">{{ item.name }}</option>
        </select>
      </div>

      <!-- Conditionally render dropdowns based on title -->
      <ng-container *ngIf="isLevelTitle(); else showAllDropdowns">
        <div class="mb-2 d-flex flex-column" *ngIf="selectedValues[0]">
          <label [attr.aria-label]="'Level ' + levelNumber + ' dropdown'" *ngIf="!loadingDropdown">Division {{levelNumber}}</label>
          <select *ngIf="!loadingDropdown" class="custom-select-sm" [(ngModel)]="selectedValues[1]" (change)="onDropdownChange(1, $event.target.value)">
            <option *ngFor="let item of getDropdownOptions(1)" [value]="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="mb-2 d-flex flex-column" *ngIf="selectedValues[1]">
          <label [attr.aria-label]="'Level ' + levelNumber + ' dropdown'" *ngIf="!loadingDropdown">Org {{levelNumber}}</label>
          <select *ngIf="!loadingDropdown" class="custom-select-sm" [(ngModel)]="selectedValues[2]" (change)="onDropdownChange(2, $event.target.value)">
            <option *ngFor="let item of getDropdownOptions(2)" [value]="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="mb-2 d-flex flex-column" *ngIf="selectedValues[2]">
          <label [attr.aria-label]="'Level ' + levelNumber + ' dropdown'" *ngIf="!loadingDropdown">Service Group {{levelNumber}}</label>
          <select *ngIf="!loadingDropdown" class="custom-select-sm" [(ngModel)]="selectedValues[3]" (change)="onDropdownChange(3, $event.target.value)">
            <option *ngFor="let item of getDropdownOptions(3)" [value]="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="mb-2 d-flex flex-column" *ngIf="selectedValues[3]">
          <label [attr.aria-label]="'Level ' + levelNumber + ' dropdown'" *ngIf="!loadingDropdown">Team Group {{levelNumber}}</label>
          <select *ngIf="!loadingDropdown" class="custom-select-sm" [(ngModel)]="selectedValues[4]" (change)="onDropdownChange(4, $event.target.value)">
            <option *ngFor="let item of getDropdownOptions(4)" [value]="item.id">{{ item.name }}</option>
          </select>
        </div>
      </ng-container>

      <!-- Show all dropdowns if the title is not 'Level' -->
      <ng-template #showAllDropdowns>
        <div class="mb-2 d-flex flex-column" *ngIf="selectedValues[0]">
          <label [attr.aria-label]="'Level ' + levelNumber + ' dropdown'" *ngIf="!loadingDropdown">Division {{levelNumber}}</label>
          <select #dropdownRef1 *ngIf="!loadingDropdown" class="custom-select-sm" [(ngModel)]="selectedValues[1]" 
                  (change)="onDropdownChange(1, $event.target.value, $event.target.options[$event.target.selectedIndex].text)">
            <option *ngFor="let item of getDropdownOptions(1)" [value]="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="mb-2 d-flex flex-column" *ngIf="selectedValues[1]">
          <label [attr.aria-label]="'Level ' + levelNumber + ' dropdown'" *ngIf="!loadingDropdown">Org {{levelNumber}}</label>
          <select #dropdownRef2 *ngIf="!loadingDropdown" class="custom-select-sm" [(ngModel)]="selectedValues[2]" 
                  (change)="onDropdownChange(2, $event.target.value, $event.target.options[$event.target.selectedIndex].text)">
            <option *ngFor="let item of getDropdownOptions(2)" [value]="item.id">{{ item.name }}</option>
          </select>
        </div>
        <!-- Repeat similarly for other dropdowns -->      
        
        <div class="mb-2 d-flex flex-column" *ngIf="selectedValues[2]">
          <label [attr.aria-label]="'Level ' + levelNumber + ' dropdown'" *ngIf="!loadingDropdown">Service Group {{levelNumber}}</label>
          <select #dropdownRef3 *ngIf="!loadingDropdown" class="custom-select-sm" [(ngModel)]="selectedValues[3]" 
                  (change)="onDropdownChange(3, $event.target.value, $event.target.options[$event.target.selectedIndex].text)">
            <option *ngFor="let item of getDropdownOptions(3)" [value]="item.id">{{ item.name }}</option>
          </select>
        </div>
        
        <div class="mb-2 d-flex flex-column" *ngIf="selectedValues[3]">
          <label [attr.aria-label]="'Level ' + levelNumber + ' dropdown'" *ngIf="!loadingDropdown">Team Group {{levelNumber}}</label>
          <select #dropdownRef4 *ngIf="!loadingDropdown" class="custom-select-sm" [(ngModel)]="selectedValues[4]" 
                  (change)="onDropdownChange(4, $event.target.value, $event.target.options[$event.target.selectedIndex].text)">
            <option *ngFor="let item of getDropdownOptions(4)" [value]="item.id">{{ item.name }}</option>
          </select>
        </div>
        
        <div class="mb-2 d-flex flex-column" *ngIf="selectedValues[4]">
          <label [attr.aria-label]="'Level ' + levelNumber + ' dropdown'" *ngIf="!loadingDropdown">Engineering Process {{levelNumber}}</label>
          <select #dropdownRef5 *ngIf="!loadingDropdown" class="custom-select-sm" [(ngModel)]="selectedValues[5]" 
                  (change)="onDropdownChange(5, $event.target.value, $event.target.options[$event.target.selectedIndex].text)">
            <option *ngFor="let item of getDropdownOptions(5)" [value]="item.id">{{ item.name }}</option>
          </select>
        </div>
      </ng-template>
    </div>

    <!-- Action buttons and spinner -->
    <div class="d-flex justify-content-end mt-2">
      <button class="btn btn-sm secondary-button" aria-label="Select Item Button" #selectItem type="button" [disabled]="!selectedValues[4]" (click)="okClick()">
        <span>Select Item</span>
      </button>
      <button type="button" class="btn p-0 mb-1 ml-2" data-toggle="collapse" data-target="#TreeViewDetails" aria-label="Collapse" title="Collapse Dropdowns" (click)="toggleTreeView()">
        <span class="fa fa-angle-double-up"></span>
      </button>
    </div>

    <!-- Loading spinner -->
    <div *ngIf="!isTreeLoaded" class="ml-2">
      <span class="fa fa-spinner fa-spin fa-2x"></span>
    </div>
  </div>
</div>
