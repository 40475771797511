import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Router, RouterModule, Routes } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AngularPlugin } from "@microsoft/applicationinsights-angularplugin-js";
import { AppComponent } from "./app.component";
import { WelcomeComponent } from "./welcome/welcome.component";
import { CommonModule } from "@angular/common";
import { MsalService, MsalGuard, MsalInterceptor, MsalModule } from "@azure/msal-angular";
import { EventBrokerModule } from "./event-broker/event-broker.module";
import { MonitoringSolutionModule } from "./monitoring-solution/monitoring-solution.module";
import { QualityOfServiceModule } from "./quality-of-service/quality-of-service.module";
import { BamDashboardModule } from "./bam-dashboard/bam-dashboard.module";
import { ScoreCardModule } from "./score-card/score-card.module";
import { CacheService } from "./service/cache.service";
import { NgbModalConfirmComponent } from "./modal/ngb-modal-confirm.component";
import { NgbModalErrorComponent } from "./modal/ngb-modal-error.component";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { NgbModalSuccessComponent } from "./modal/ngb-modal-success.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { OneAuthZService } from "./service/oneAuthZ.service";
import { AuthRoleGuard } from "./auth-role-guard";
import { APP_CONFIG } from "./common/constants";
import { AppConfig } from "./model/app-config.model";
import { FeatureFlagGuard } from "./feature-flag-guard";
import { Observable } from "rxjs";
import { AuthenticationResult } from "@azure/msal-browser";
import { map } from "rxjs/operators";
import { ControlTowerModule } from "./control-tower/control-tower.module";

// default routes for app.
const appRoutes: Routes = [
  { path: "", component: WelcomeComponent, canActivate: [MsalGuard] },
  {
    path: "contact-us",
    component: ContactUsComponent,
    canActivate: [MsalGuard],
  },
  { path: "**", component: WelcomeComponent, canActivate: [MsalGuard] },
];

const appInitializerFn = (msalService: MsalService, appInsights: ApplicationInsights) => (): Observable<any> =>
  msalService.handleRedirectObservable().pipe(
    map((x: AuthenticationResult) => {
      if (x != null) {
        msalService.instance.setActiveAccount(x.account);
      } else {
        if (msalService.instance.getAllAccounts().length > 0) {
          msalService.instance.setActiveAccount(msalService.instance.getAllAccounts()[0]);
        } else {
          msalService.loginRedirect();
        }
      }

      // set current authentication context.
      appInsights.setAuthenticatedUserContext(msalService.instance.getActiveAccount()?.localAccountId);
      appInsights.trackTrace({ message: "Application Initialized." });
    })
  );

// factory for initializing application insights.
const applicationInsightsInitialization = (appConfig: AppConfig, router: Router) => {
  const angularPlugin = new AngularPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: appConfig.AIKey,
      enableCorsCorrelation: true,
      extensions: [angularPlugin],
      extensionConfig: {
        [angularPlugin.identifier]: { router },
      },
    },
  });
  appInsights.loadAppInsights();
  return appInsights;
};

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    NgbModalConfirmComponent,
    NgbModalErrorComponent,
    NgbModalSuccessComponent,
    ContactUsComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgbModalModule,
    // loading modules for sub-apps for routing.
    EventBrokerModule,
    MonitoringSolutionModule,
    QualityOfServiceModule,
    BamDashboardModule,
    ScoreCardModule,
    ControlTowerModule,
    MsalModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: "legacy" }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: ApplicationInsights,
      useFactory: applicationInsightsInitialization,
      deps: [APP_CONFIG, Router],
    },
    MsalService,
    MsalGuard,
    AuthRoleGuard,
    FeatureFlagGuard,
    CacheService,
    OneAuthZService,
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [MsalService, ApplicationInsights],
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
